<template>
  <v-app>
    <!--layout-app-app-bar /-->
    <!--layout-pos-drawer :key="keyRender" /-->
    <layout-pos-view />
    
  </v-app>
</template>
  
  <style>
.v-main {
  background: #fafafa !important;
}
.v-main__wrap {
  padding-top: 18px !important;
}
.v-item--active {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
</style>
  
  <script>

export default {
  name: "PosIndex",
  components: {},
  data: () => ({
    expandOnHover: false,
    keyRender: 0,
    ws: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.$auth.loadInfoSession();
  },
  mounted() {},
  methods: {},
};
</script>